.product-upload-image,
.product-upload-image * {
  box-sizing: border-box;
}
.product-upload-image {
  background: linear-gradient(to left, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3));
  border-radius: 8px;
  width: 372px;
  height: 302px;
  position: relative;
  overflow: hidden;
}



.product-edit-image{
  display: flex;    
  cursor: pointer;
  height: 3rem;
  border: 0.1rem solid #d0c9d705;
  border-radius: 0.5rem;
  position: absolute;
  justify-content: center;
  align-items: center;
  padding: 1rem;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.0);
}

.product-edit-image > input {
  /* background-color: red; */
  width: 100%;
  height: 100%;
  border: none;
  padding-left: 1rem;
  padding-right: 1rem;
  background-color: transparent;
  position: absolute;
  outline: none;
  opacity: 0;
  z-index: 2;
  
}

.product-edit-image-placeholder {
  background-color: rgba(255, 255, 255, 0.80);
  min-height: 80%;
  width: 90%;
  padding: 0.4rem;
  border-radius: 1.5px;
  border: 0.5px solid #33333337;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1.5rem;
  position: relative;
  overflow: hidden;
  text-align: center;
  color: #7975B6;
}