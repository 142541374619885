@keyframes slideLeft {
  from {
    margin-right: -30%;
  }
  to {
    margin-right: 0px;
  }
}

.wallet-edit-modal-container {
  justify-content: flex-end;
}

.wallet-modal-cont {
  padding: 20px;
  overflow-y: auto;
}

.wallet-modal-btn {
  width: 80%;
  background: white;
  border-radius: 8px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.5s;
  font-family: "Sora", sans-serif;
  outline: none;
  border-style: none;
  color: black;
  font-weight: bold;
  padding: 15px;
  font-size: small;
  border-radius: 8px;
  border: 1px solid var(--primary-primary-50, #7975b6);
  margin-top: 20px;
}

.edit-wallet-modal-body {
  min-height: 100vh;
  max-height: 100vh;
  max-width: 30%;
  min-width: 30%;
  animation: slideLeft 1s;
  overflow-y: auto;
}

.each-box-withdraw {
  border-bottom: 1px solid rgb(212, 212, 212);
  margin-top: 15px;
  width: 100%;
}

.w-order-d-items {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  max-height: 30vh;
  overflow-y: auto;
}
