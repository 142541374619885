.screen-container-support {
  display: flex;
  flex-direction: column;
  overflow-x: hidden;
  min-height: 80vh;
}

.screen-container-support button {
  outline: none;
  border: none;
}

.support-heading {
  display: flex;
  align-items: center;
}

.support-heading .back-button {
  margin-right: 10px;
  background: transparent;
}

.support-heading div:nth-child(2) {
  flex-grow: 1;
  text-align: center;
}

.FAQ {
  color: #1d242d;
  font-size: 24px;
  line-height: 32px;
}

.support-search {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  gap: 10px;
  color: #47586e;
  font-size: 14px;
  width: 40%;
  align-self: center;
  padding: 10px 0 10px 10px;
  border-radius: 8px;
  border: 1px solid #3a354180;
}

.search-icon {
  width: 10px;
}

.support-search input,
.support-search input:focus {
  border: none;
  outline: none;
  flex-grow: 1;
}

.support-categories {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 30px;
  margin-top: 20px;
}

.support-category {
  border: 1px solid #3a354180;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: transparent;
  border-radius: 8px;
  padding: 20px 0;
}

.support-description {
  font-size: 12px;
  line-height: 16px;
  color: #47586e;
  width: 80%;
}

.support-category-title {
  color: #1d242d;
  line-height: 25px;
}

.support-contact {
  align-self: center;
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 10px;
  border: 1px solid #7975b6;
  padding: 10px;
  margin-top: auto;
}

.contact-support-heading {
  color: #1d242d;
  font-size: 20px;
  line-height: 25px;
}

.contact-support-text {
  color: #47586e;
  font-size: 14px;
  line-height: 17px;
  text-align: center;
}

.support-contact button {
  background: #34204d;
  border-radius: 8px;
  padding: 10px 20px;
  color: #fff;
  font-size: 14px;
  line-height: 17px;
  cursor: pointer;
}

.selected-topic {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  align-self: flex-start;
  margin: 20px 0 30px 0;
}

.selected-topic img {
  width: 30px;
  height: 30px;
}

.selected-topic .category {
  color: #1d242d;
}

.selected-topic .text {
  color: #47586e;
  font-size: 14px;
}

.faq-container {
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  row-gap: 1rem;
  column-gap: 4rem;
  margin-top: 20px;
}

.faq-answer {
  font-size: 14px;
  line-height: 17px;
  margin-top: 10px;
  transition: opacity 0.5s ease, transform 0.5s;
  opacity: 0;
  transform: scaleY(0);
  transform-origin: top;
}

.faq-question .question {
  margin-bottom: 5px;
  cursor: pointer;
}

.faq-question {
  display: flex;
  align-items: center;
  border-bottom: 1px solid#3A354180;
  justify-content: space-between;
}

.faq-question button {
  background: transparent;
}

.faq-item {
  transition: max-height 0.5s ease, margin 0.5s ease;
  margin-top: -10px;
}

.faq-item.open {
  max-height: 1000px;
  margin-bottom: 10px;
}

.faq-item.open .faq-answer {
  opacity: 1;
  transform: scaleY(1);
}
