.absolute-filter-modal-container {
  width: 250px;
  height: fit-content;
  border-radius: 12px;
  background: var(--White, #fff);
  box-shadow: 0px 0px 20px 4px rgba(44, 44, 44, 0.25);
  position: absolute;
  z-index: 2000;
}

.close-filter-modal {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 15px;
  position: absolute;
}

.absolute-filter-modal-body {
  margin-top: 40px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 10px;
  padding: 15px;
}

.absolute-filter-modal-start,
.absolute-filter-modal-date {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 10px;
  width: 100%;
  border-bottom: 1px solid var(--others-strokes, rgba(58, 53, 65, 0.23));
  padding-bottom: 12px;
}

.absolute-filter-modal-date {
  border-bottom: 0px solid white;
}

.absolute-filter-modal-date div {
  width: 100%;
}

.absolute-filter-date-in {
  border-style: none;
  border-radius: 5px;
  background-color: rgb(242, 242, 242);
  width: 100%;
  height: 40px;
  padding: 15px;
  font-size: 13px;
  color: rgb(110, 109, 109);
}

input[type="date"]::-webkit-calendar-picker-indicator {
  opacity: 0.5;
  font-size: medium;
}

.absolute-filter-modal-actions {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  gap: 15px;
  width: 100%;
  align-items: center;
}

.absolute-filter-modal-actions p {
  color: rgb(110, 109, 109);
}

.absolute-filter-modal-actions button {
  width: 100px;
  height: 35px;
}
