.tab-pane {
    width: 100%;
    display: flex;
    flex-direction: row;
    min-height: 86vh;
}

.tab-pane-tab-wrapper {
    display: flex;
    flex-direction: column;
    gap: 5px;
    min-width: 23%;
    padding: 1rem;
    height: inherit;
    border-right: 1px solid var(--others-divider, rgba(58, 53, 65, 0.12));
    min-height: 86vh;
    overflow-y: auto; 
}

.tab-pane-tab {
    display: flex;
    padding: 1rem;
    align-items: center;
    gap: 1rem;
    align-self: stretch;
    border-radius: 0.5rem;
    background: var(--estiity-brand-colors-primary-color-2, #9792E3);

/* label */
    /* font-family: Rubik; */
    font-size: 0.75rem;
    font-style: normal;
    font-weight: 500;
    line-height: 1rem; /* 133.333% */
    letter-spacing: 0.06rem;
    size: 0.5rem;
}


.tab-pane-content {
    width: 100%;
    padding: 1rem;
    min-height: 86vh;
}


.tab-pane-tab > div {
    display: flex;
    gap: 0.5rem;
    align-items: center;
}