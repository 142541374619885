.success-container {
  background: #FCFCFD;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 1rem;    
  animation: zoomOut 0.6s ease-in-out;
  overflow: auto;
  border-radius: 5px;
  width: 34rem;
  }
  
  .success-title {
    color: #52AC10;
    font-weight: 600;
    margin-top: -1.5rem;
  }
  
  .success-container .close-button {
    text-align: end;
    margin-left: auto;
    background: none;
    cursor: pointer;
    width: fit-content;
      border: none;
      outline: none;
      cursor: pointer;
  }
  
  .success-button {
      background: #E6F7D9;
      padding: 5px;
      border-radius: 50%;
      margin-top: 20px;
    }
  
  .success-container button {
    border: none;
    margin-top: 10px;
    outline: none;
    background: #1D0B32;
    color: #FFFDFD;
    padding: 10px 0;
    width: 70%;
    border-radius: 5px;
  }
  
  .success-text {
    margin-top: 20px;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
  }
  
  .success-text p{
    width: 60%;
  }
  
  @media screen and (max-width: 768px) {
    .success-container{
      min-width: 100%;
    }
    
  }