.bankpopup-container {
  width: fit-content;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 20px;
}

.bankpopup-close-button {
  background: transparent;
  outline: none;
  border-style: none;
  margin-left: 400px;
}

.bankpopup-header {
  font-size: 19px;
  font-weight: 700;
  text-align: center;
  color: rgba(52, 32, 77, 1);
}

.bank-popup-form {
  display: flex;
  flex-direction: column;
  gap: 20px;
  align-items: center;
  padding-top: 30px;
}

.bank-popup-form input {
  border-radius: 5;
  padding: 10;
  border: 1px solid rgb(201, 199, 199);
  background-color: white;
}

.bank-popup-form input:focus {
  background-color: white;
}

@media (max-width: 576px) {
  .bankpopup-container {
    width: auto;
    height: auto;
    top: auto;
    left: auto;
    padding: 20px;
    margin: 2rem;
  }
}
