.search-header-container {
  display: flex;
  flex-direction: row;
  gap: 0.7rem;
  align-items: center;
}


.search-header-container-custom-menu-wrapper {
    display: flex;
    gap: 0.7rem;
    flex-direction: row;
}