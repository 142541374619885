.search-input-box-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 2.5rem;
  width: 100%;
  padding: 1.1875rem 1rem;
  gap: 0.5rem;
  border-radius: 0.5rem;
  border: 1px solid var(--others-overlay, rgba(58, 53, 65, 0.5));
  align-self: center;
  color: var(--text-colours-text-10, #47586e);
}

.search-input-box-wrapper > input {
  width: 100%;
  outline: none;
  border: none;
  background-color: transparent;
  font-size: small;
}
