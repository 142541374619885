.o-each-d-stat-container {
  width: 230px;
  height: 100px;
  border-radius: 10px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding: 0px 0px 0px 15px;
  gap: 10px;
  border: 1px solid rgb(202, 199, 199);
  margin-top: 15px;
}

.o-stat-text {
  color: "#474F59";
  font-size: 20px;
}

.o-each-d-stat-container-one {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  width: 200px;
}

.order-d-1 {
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  border-radius: 8px;
  border: 1.5px solid var(--others-strokes, rgba(58, 53, 65, 0.23));
  padding: 20px;
  max-height: 67vh;
  min-height: 67vh;
  overflow-y: auto;
}

.order-d-items {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  max-height: 45vh;
  min-height: 45vh;
  overflow-y: auto;
}

.order-d-2 {
  width: 40%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  max-height: 67vh;
  min-height: 67vh;
  overflow-y: auto;
  gap: 20px;
}

.order-d-2-1,
.order-d-2-2 {
  border-radius: 8px;
  border: 1.5px solid var(--others-strokes, rgba(58, 53, 65, 0.23));
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 20px;
  height: 260px;
  width: 100%;
}

.order-d-2-2 {
  max-height: 180px;
}

.order-item-img {
  max-width: 50px;
  max-height: 50px;
  object-fit: contain;
}

.order-item-cont {
  width: 100%;
  height: fit-content;
  border-bottom: 1px solid var(--others-strokes, rgba(58, 53, 65, 0.23));
  padding: 15px;
  margin-bottom: 10px;
}

.order-details-text-area {
  border-radius: 4px;
  border: 1px solid var(--others-strokes, rgba(58, 53, 65, 0.23));
  width: 100%;
  padding: 10px;
  outline: none;
  font-size: small;
}
