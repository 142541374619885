.carousel-broadcast-container {
  border-radius: 8px;
  border: 1px solid var(--others-strokes, rgba(58, 53, 65, 0.23));
  background: #ede3f9;
  width: 100%;
  height: 80px;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
}

.c-b-arrow-container {
  height: 80px;
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 5%;
  cursor: pointer;
  justify-content: center;
  transition: all 0.5s;
  outline: none;
  border-style: none;
  background: transparent;
}

.c-b-arrow-container:hover {
  background-color: #e7d7fb;
}

.c-b-the-message-container {
  width: 90%;
  padding: 15px;
}
