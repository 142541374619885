
.permit-check-child {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 1rem;
    align-self: stretch;
    color: var(--black-100);
}


.permit-check-child-value {
    color: var(--black-100);
    font-family: Rubik;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.25rem;
}


.permit-check-child-checked, .permit-check-child-unchecked {
    font-size: 1.2rem;
    color: var(--black-60);
    cursor: pointer;
}


.permit-check-child-checked {
    color: #7975B6;
}