.skill-input-container {
  display: flex;
  gap: 8px;
  width: 100%;
  padding: 10px;
  border: 1px solid rgba(0, 0, 0, 0.194);
  border-radius: 5px;
  background-color: white;
  flex-direction: column;
}

.skill-tag {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--primary-50);
  color: #fff;
  padding: 6px 12px;
  border-radius: 20px;
  margin-right: 8px;
  font-size: 14px;
  transition: all 0.3s ease;
}

.skill-tag button {
  background: none;
  border: none;
  color: inherit;
  cursor: pointer;
  font-weight: bold;
  margin-left: 6px;
}
.skill-tag button:hover {
  color: var(--error-30);
}

.skill-tag:hover {
  transform: scale(1.01);
}

.skill-tag::after {
  content: "";
  width: 8px;
  height: 8px;
  background-color: #fff;
  border-radius: 50%;
  position: absolute;
  top: 50%;
  right: -4px;
  transform: translateY(-50%);
}

.skill-input {
  border: none;
  padding: 2px;
  border-radius: 4px;
  outline: none;
  font-size: 16px;
  color: black;
  background-color: white;
  line-height: 35px;
}

.skill-input:focus {
  border: 0.5px solid var(--primary-50);
  /* box-shadow: 0 0 5px rgba(0, 0, 0, 0.7); */
}

.skill-input-wrapper {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  justify-content: center;
}
