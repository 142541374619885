.w-each-d-stat-container {
  width: 260px;
  height: 100px;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 0px 0px 0px 15px;
  gap: 10px;
  border: 1px solid rgb(202, 199, 199);
  margin-top: 15px;
}
