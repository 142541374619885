.action-card-table {
  background: var(--black-0, #fff);
  padding: 0px;
  box-shadow: 0.25rem 0.3rem rgba(0, 0, 0, 0.02);
  border-radius: 0.25rem;
  /* background-color: red; */
  -webkit-box-shadow: 3px 3px 11px 1px #dddddd;
  -moz-box-shadow: 3px 3px 11px 1px #dddddd;
  box-shadow: 3px 3px 11px 1px #dddddd;
}

.action-card-table-list {
  display: flex;
  flex-direction: row;
  gap: 0.5rem;
  color: var(--black-60, #838383);
  align-items: center;
  justify-content: flex-start;
  border-bottom: 0.5px solid var(--others-strokes, rgba(58, 53, 65, 0.23));
  padding: 20px;
  width: 230px;
  transition: all 0.3s;
}

.action-card-table-list:hover {
  background-color: #f7f7f7;
}

.action-card-table-list-icon-wrapper {
  border-right: 0.01rem solid var(--black-10);
  display: flex;
  align-items: center;
}
.action-card-table-list-label {
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.25rem;
}
