.side-nav-container {
  width: 20%;
  height: 100vh;
  background: #34204d;
  padding-top: 30px;
  overflow-y: auto;
}

.estility-logo-side-nav {
  width: 40px;
}

.side-nav-intro {
  gap: 10px;
  justify-content: center;
}

.nav-items-overall {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  height: 82vh;
  width: 100%;
}

.nav-items-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 15px;
  padding: 20px 20px 0px 20px;
  width: 100%;
}

.copyright-container {
  margin-top: 150px;
}

.each-nav {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  gap: 20px;
  flex-wrap: wrap;
  align-items: center;
  padding: 10px;
  padding-left: 20px;
  cursor: pointer;
  transition: all 0.2s;
  border-radius: 10px;
}

.each-nav:hover {
  opacity: 0.8;
}

.each-nav-active {
  background: rgba(0, 0, 0, 0.5);
  border-left: 3px solid var(--estiity-brand-colors-bright-purple, #c1beeb);
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
}
