

.accountinfo-edit-info-container {
    display: flex;
    gap: 2rem;
    flex-direction: column;

}


.accountinfo-edit-info-form {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
}

.accountinfo-edit-info-form-group {
    display: flex;
    flex-direction: row;
    gap: 1.5rem;
}


.accountinfo-edit-info-form-action-f-right {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 2rem;
    align-self: stretch;
}


.accountinfo-edit-info-cancel-btn {
    color: var(--text-colours-text-10, #47586E);
    font-family: Sora;
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: 160%; 
    letter-spacing: 0.01rem;
    cursor: pointer;
}



.accountinfo-edit-info-change-logo{
    display: flex;    
    cursor: pointer;
    position: relative;
    height: 3rem;
    border: 0.1rem solid #34204C;
    border-radius: 0.5rem;
    position: relative;
    justify-content: center;
    align-items: center;
    padding: 1rem;
}

.accountinfo-edit-info-change-logo > input {
    /* background-color: red; */
    width: 100%;
    height: 100%;
    border: none;
    padding-left: 1rem;
    padding-right: 1rem;
    background-color: transparent;
    position: absolute;
    outline: none;
    opacity: 0;
}


.estate-info-container {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

/* .estate-info- */

.estate-info-first-child-logo {
    display: flex;
    width: 4.875rem;
    height: 4.875rem;
    justify-content: center;
    align-items: center;
    gap: 0.625rem;
    border-radius: 0.5rem;
    border: 0.5px solid var(--primart-290, #1D0B32);
    background: #FFF;
    position: relative;
}

.estate-info-first-child-logo > img {
    width: 100%;
    height: 100%;
    border-radius: 0.5rem;
}

.estate-info-first-child {
    display: flex;
    flex-direction: row;
    gap: 0.5rem;
    align-items: center;
}

.estate-info-title {
    color: #000;
    font-family: Sora;
    font-size: 1.5rem;
    font-style: normal;
    font-weight: 400;
    line-height: 160%; /* 2.4rem */
    letter-spacing: -0.015rem;
}

.estate-info-child {
    margin-top: 1.5rem;
    display: flex;
    align-items: flex-start;
    gap: 5rem;
    align-self: stretch;
}

.estate-info-child > div {
    display: flex;
    flex-direction: column;
    gap: 0.2rem;
}


.estate-info-action-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: end;
    color: #250D86;
    font-family: Sora;
    font-size: 1rem;
    font-style: normal;
    font-weight: 600;
    line-height: 160%; /* 1.6rem */
    letter-spacing: -0.01rem;
    margin-top: 1rem;
}

.estate-info-action-wrapper > div {
    display: flex;
    flex-direction: row;
    cursor: pointer;
    align-items: center;
    gap: 0.5rem;
}

.estate-info-change-logo {
    display: flex;
    padding: 0.9375rem 2.5rem;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 0.625rem;
    border-radius: 0.25rem;
    border: 2px solid #34204C;
    color: #34204C;
    font-family: Sora;
    font-size: 1rem;
    font-style: normal;
    font-weight: 600;
    line-height: 160%; /* 1.6rem */
    letter-spacing: -0.01rem;
}