.d-each-d-stat-container {
  width: 230px;
  height: 100px;
  border-radius: 10px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding: 0px 0px 0px 15px;
  gap: 10px;
  border: 1px solid rgb(202, 199, 199);
  margin-top: 15px;
}

.d-stat-text {
  color: "#474F59";
  font-size: 20px;
}

.d-each-d-stat-container-one,
.d-each-d-stat-container-two {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}

.d-each-d-stat-container-one {
  width: 25%;
  min-width: 250px;
}

.d-each-d-stat-container-two {
  width: 40%;
  min-width: 350px;
}

.d-each-d-stat-container-three {
  width: 25%;
  min-width: 250px;
  justify-content: space-between;
  padding-right: 10px;
}

.circle-task-complete {
  border: solid rgb(190, 186, 186);
  width: 90px;
  height: 90px;
  border-radius: 90px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: large;
  opacity: 0.8;
  text-align: center;
  border-width: 5px;
  border-color: red;
}

.circle-complete-25perc {
  border-top-color: green;
}

.circle-complete-50perc {
  border-top-color: green;
  border-right-color: green;
}

.circle-complete-75perc {
  border-top-color: green;
  border-right-color: green;
  border-bottom-color: green;
}

.circle-complete-100perc {
  border-top-color: green;
  border-right-color: green;
  border-bottom-color: green;
  border-left-color: green;
}
