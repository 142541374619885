.login-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  height: 100vh;
  width: 100%;
}

.login-container-one,
.login-container-two {
  display: flex;
  flex-direction: column;
  height: 100vh;
}

.login-container-one {
  width: 60%;
}

.login-container-two {
  width: 40%;
}

.login-banner-img {
  object-fit: cover;
  max-width: 100%;
  max-height: 100%;
  min-height: 100vh;
}

.wavy-container {
  height: 150px;
  display: flex;
}

.wavy-img {
  position: absolute;
  width: 250px;
  z-index: 5;
}

.wavy-content {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 25px;
  position: relative;
  z-index: 10;
  width: 100%;
}

.login-cont {
  margin-top: 50px;
  padding: 20px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
}

.login-in {
  margin-top: 20px;
  width: 55%;
}

.login-btn {
  width: 55%;
  margin-top: 20px;
}

.forgot-remember-login {
  width: 55%;
}

#inlineCheckbox3[type="checkbox"] {
  border: 2px solid #323335;
  border-radius: 3px;
}

#inlineCheckbox3[type="checkbox"]:checked {
  border-color: rgb(26, 25, 25);
  background: rgb(12, 12, 12);
  color: rgb(19, 18, 18);
  content: ".";
}

.code-boxes {
  width: 50px;
  height: 50px;
  border: 1px solid rgb(202, 202, 202);
  border-radius: 5px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.code-boxes input {
  width: 70%;
  outline: none;
  border-style: none;
  background-color: transparent;
  text-align: center;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
