
.product-dashboard {
    background-color: #ffff;
    min-height: inherit;
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    padding: 0.2rem;
}

.product-dashboard-stat-wrapper {
    width: 100%;
    display: flex;
    flex-direction: row;
    overflow-y: auto;
    gap: 1rem;
}