.slp-custom-select {
    position: relative;
    display: inline-block;
    width: 100%;
  }
  
  .slp-select-box {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 3.5rem;
    padding: 1rem 0.75rem 1rem 1rem;
    border-radius: 0.25rem;
    border: 1px solid var(--others-strokes, rgba(58, 53, 65, 0.23));
    border: 1px solid #ccc;
    font-family: Arial, sans-serif;
    cursor: pointer;
    width: inherit;
    gap: 0.2rem;
  }
  
  .slp-option-list {
    position: absolute;
    top: 100%;
    left: 0;
    background-color: white;
    border: 1px solid #ccc;
    max-height: 150px; /* Adjust this value as needed */
    overflow-y: auto;
    width: 100%;
    border-radius: 4px;
    z-index: 100;
  }
  
  .slp-option {
    padding: 8px;
    cursor: pointer;
  }
  
  .slp-option:hover {
    background-color: #e0e0e0;
  }