
.table {
  width: 100%;
  border-collapse: collapse;
}

.table-active-pill {
    display: flex;
    padding: 0.125rem 0.25rem;
    align-items: center;
    text-align: center;
    border-radius: 1.5rem;
    justify-content: center;
}

  .table-active-pill-text-active {
    text-align: center;
    font: var(--caption, 400 12px/20px "Rubik", sans-serif);
    position: relative;
  }



 

  .table-thead {
    height: 3rem;
    color: #474F59;
    font-family: "Sora", sans-serif;
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.5rem;
    border-radius: 10px;
    border-top-left-radius: 10px;
  }


  

  .table-thead-tr .table-th:first-child {
    border-top-left-radius: 15px;
  }

  .table-thead-tr .table-th:last-child {
    border-top-right-radius: 15px;
  }

  .table-thead-tr .table-th {
    background: #7152F30D;
  }

  .table-th {
    padding: 0.5rem 1rem;
    font-size: 0.75rem;
    font-style: normal;
    font-weight: bold;
    line-height: 1rem; 
    letter-spacing: 0.06rem;
    text-align: left;
    font-family: "Sora", sans-serif;
    min-width: 15px;
  }
  
  .table-th-to-uppercase {
    text-transform: uppercase;
  }

  .table-tbody-tr {
    height: 2.5rem;
    border-bottom: .5px solid var(--black-20, #d5d7da);    
    cursor: pointer;
  }

  .table-tbody-tr:hover {
    background: #342a5f0d;
  }


  .table-tbody-tr-td {
    font-size: 0.799rem;
    color: var(--black-60, #838383);
    font-weight: 400;
    text-overflow: ellipsis;
    align-items: center;
    align-self: stretch;
    width: auto;
    /* 
   
    overflow: hidden;
    font-style: normal;
    */
  }


  .table-tbody-tr-td-action-option {
    position: absolute;
    right: 1.5rem;
    z-index: 5;
  }


  .table-container {
    overflow-x: auto; 
    scrollbar-width: thin;
    scrollbar-color: var(--primaryColor) transparent;
    gap: 2rem;
  }

  .table-checkbox {
    cursor: pointer;
  }