.top-bar-container {
  border-bottom: 1px solid #d2d1dc;
  height: 80px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0px 25px 0px 25px;
}

.top-bar-profile-pic {
  width: 40px;
  border-radius: 40px;
}

.top-bar-status {
  padding: 0px 5px 0px 5px;
  background: var(
    --light-background-blue,
    linear-gradient(0deg, rgba(0, 133, 255, 0.1) 0%, rgba(0, 133, 255, 0.1) 100%),
    #fff
  );
  margin: 0;
  border-radius: 5px;
  color: #0085ff;
}

.top-bar-btn {
  background: #34204c !important;
  width: 150px;
}

.hide {
  display: none;
}
