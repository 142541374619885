

.security-tile-title {
    color: var(--text-colours-text-30, #1D242D);
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: Sora;
    font-size: 1rem;
    font-style: normal;
    font-weight: 600;
    line-height: 1.3125rem;
    letter-spacing: 0.01563rem
}


.security-tile-text {
    color: var(--text-colours-text-30, #1D242D);
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: Sora;
    font-size: 1rem;
    font-style: normal;
    font-weight: 300;
    line-height: 1.3125rem; /* 131.25% */
    letter-spacing: 0.01563rem;
}

.change-password-overlay {
        width: 100%;
        max-height: 100vh;
        min-height: 100vh;
        position: absolute;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        z-index: 1000;
        background: rgba(0, 0, 0, 0.5);
        top: 0;
        left: 0;
        overflow: auto;
}

.change-password-overlay-contents {
    box-shadow: 0 5px 10px 0 rgba(92, 92, 100, 0.25);
    background-color: #fff;
    animation: zoomOut 0.6s ease-in-out;
    padding: 1.5rem;
    border-radius: 1rem;
    min-height: 15rem;
    width: 20rem;
    position: relative;

}


@keyframes zoomOut {
    from {
      transform: scale(0);
    }
  
    to {
      transform: scale(1);
    }
  }

