.notification-body {
  min-width: 500px;
  max-width: 500px;
}

.notification-title-div .title {
  color: #1d242d;
  font-weight: 700;
}

.notification-container {
  display: flex;
  flex-direction: column;
  margin-top: 20px;
  min-height: 80vh;
}

.toggle-page .toggle-title {
  color: #1d242d;
  font-weight: 700;
}

.notification-button-items {
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-top: 20px;
  max-height: 80vh;
  overflow-y: auto;
}

.toggle-page {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.mark-all {
  border: 1px solid #7975b6;
  border-radius: 8px;
  margin-top: 30px;
  color: #7975b6;
  font-size: 14px;
  background: transparent;
  padding: 5px 0;
}

.notification-title-div {
  display: flex;
  justify-content: space-between;
}

.container-button {
  border: 1px solid lightgray;
  background: #f8f8fc;
}

.notification-title-div button {
  border: none;
  outline: none;
  cursor: pointer;
  border-radius: 50%;
}

.notification-boxes {
  display: flex;
  flex-direction: column;
}

.notification-items {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
}

.notification-content-title {
  display: flex;
  align-items: start;
  flex-direction: column;
  text-align: start;
  gap: 10px;
  /* border: 1px solid red; */
}

.notification-content-title .title {
  color: #1d242d;
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
}

.notification-content-title .text,
.selectedItem-text {
  color: #47586e;
  line-height: 25px;
  font-size: 16px;
}

.notification-content-date {
  display: flex;
  align-items: center;
  flex-direction: column;
}

.notification-content-date .notification-status {
  font-size: 12px;
  font-weight: 700;
  color: #70c1b3;
}

.notification-pagination {
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
}

.pagination-entries {
  display: flex;
  gap: 10px;
  align-items: center;
}

.pagination-entries .entries-input {
  width: 40px;
  border-radius: 5px;
  border: 1px solid lightgray;
  outline: none;
  text-align: center;
  font-size: 14px;
}

.notification-content-date .notification-date,
.selectedItem-text {
  font-size: 14px;
  color: #1d242d;
}

.selectedItem-container {
  display: flex;
  flex-direction: column;
  padding: 10px;
}

.selectedItem-heading {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.selectedItem-heading .selectedItem-title {
  color: #1d242d;
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
}

.toggle-buttons {
  display: flex;
  justify-content: space-between;
}

.back-button {
  width: fit-content;
}

@keyframes slideLeft {
  from {
    margin-right: -30%;
  }
  to {
    margin-right: 0px;
  }
}

.edit-wallet-modal-body {
  min-height: 100vh;
  max-height: 100vh;
  max-width: 30%;
  min-width: 30%;
  animation: slideLeft 1s;
  overflow-y: auto;
}

.animate-modal {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}
