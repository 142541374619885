

.stat-card-container {
    border: 1px solid var(--others-strokes, rgba(58, 53, 65, 0.23));
    border-radius: 0.5rem;
    height: 6rem;
    padding: 1rem;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 1rem;
    width: 18rem;
    justify-content: space-between;
    cursor: pointer;
}


.stat-card-container > div {
    height: 100%;
}

.stat-card-label-wrapper {
    display: flex;
    flex-direction: column;
    width: 70%;
    justify-content: space-between;
}

.stat-card-label {
    font-size: 0.8rem;

}

.stat-card-label-value {
    font-size: 1.2rem;
    font-weight: 600;
}

.stat-card-child {
}

.stat-card-icon-all {
    display: flex;
    width: 2.25rem;
    height: 2.25rem;
    padding: 0.375rem;
    justify-content: center;
    align-items: center;
    background: rgba(239, 239, 251, 0.50);
    color: var(--primary-50);
    border-radius: 0.25rem;
}

.stat-card-icon-active {
    display: flex;
    width: 2.25rem;
    height: 2.25rem;
    padding: 0.375rem;
    justify-content: center;
    align-items: center;
    background: var(--success-10);
    color: var(--success-30);
    border-radius: 0.25rem;
}

.stat-card-icon-low {
    display: flex;
    width: 2.25rem;
    height: 2.25rem;
    padding: 0.375rem;
    justify-content: center;
    align-items: center;
    background: var(--warning-10);
    color: var(--warning-30);
    border-radius: 0.25rem;
}

.stat-card-icon-sold {
    display: flex;
    width: 2.25rem;
    height: 2.25rem;
    padding: 0.375rem;
    justify-content: center;
    align-items: center;
    background: var(--error-10);
    color: var(--error-30);
    border-radius: 0.25rem;
}