.MuiToolbar-root p {
  margin: 0 !important;
}

.MuiTableContainer-root {
  min-height: fit-content !important;
  max-height: fit-content !important;
}

.MuiTableCell-stickyHeader {
  background-color: rgba(113, 82, 243, 0.05) !important;
}

.pending-text {
  color: var(--warning-warning-30, #ffb400);
  border-radius: 15px;
  padding: 5px 35px 5px 35px;
  font-family: "Sora", sans-serif;
}

.medium-severity-c {
  background-color: #fff4d9;
  color: #ffb400;
  border-radius: 15px;
  padding: 5px 20px 5px 20px;
  font-family: "Sora", sans-serif;
}

.high-severity-c {
  background-color: #ffe4e5;
  color: #ff4c51;
  border-radius: 15px;
  padding: 5px 33px 5px 33px;
  font-family: "Sora", sans-serif;
}

.low-severity {
  background-color: #dcf3ff;
  color: #16b1ff;
  border-radius: 15px;
  padding: 5px 20px 5px 20px;
  font-family: "Sora", sans-serif;
}

.medium-severity {
  background-color: #fff4d9;
  color: #ffb400;
  border-radius: 15px;
  padding: 5px 20px 5px 20px;
  font-family: "Sora", sans-serif;
}

.high-severity {
  background-color: #ffe4e5;
  color: #ff4c51;
  border-radius: 15px;
  padding: 5px 20px 5px 20px;
  font-family: "Sora", sans-serif;
}
