.main-button {
    display: flex;
    flex-direction: row;
    padding: 0.3375rem 1.5rem;
    justify-content: center;
    align-items: center;
    gap: 0.625rem;
    border-radius: 0.5rem;
    font-family: Sora;
    font-size: 1rem;
    font-style: normal;
    font-weight: 500;
    line-height: 160%; /* 1.6rem */
    letter-spacing: 0.01rem;
    border: none;
    cursor: pointer;
    white-space: nowrap;

}


.main-button-fill {
    background: #34204C;
    color: var(--others-white, #FFF);
}

.main-button-outline {
    color: #34204C;
    background: var(--others-white, #FFF);
    border: 0.7px solid #34204C;
}