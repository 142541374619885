.filter-container {
  min-width: 400px;
  border-radius: 20px;
  padding: 20px 1.5rem;
}

.filter-headings {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.filter-date {
  width: 100%;
}

.filter-date input {
  outline: none;
  border: 1px solid #e3e8ee;
  background: #fff;
  padding: 5px 20px;
  border-radius: 10px;
  width: 100%;
}

.form-filters {
  display: flex;
  flex-direction: column;
  min-width: 100%;
  gap: 10px;
  width: fit-content;
}

.label {
  color: #01112c;
}

.filter-text {
  color: #161616;
  font-weight: 600;
}

.clear-all {
  color: #eb001b;
  background: transparent;
}

.form-filters select {
  outline: none;
  border: 1px solid #e3e8ee;
  background: #fff;
  padding: 10px 20px;
  border-radius: 10px;
  width: 100%;
}

.cancel-div {
  display: flex;
  justify-content: center;
  align-items: center;
}

.cancel {
  outline: none;
  border: none;
  background: #fff;
  color: #eb001b;
  font-size: 12px;
  font-weight: 600;
  float: center;
  text-transform: uppercase;
  margin-top: 10px;
}

.filter-input-button button {
  border: none;
  margin-top: 20px;
  outline: none;
  background: #1d0b32;
  color: #fffdfd;
  padding: 10px 0;
  width: 100%;
  border-radius: 5px;
  font-size: 14px;
}

@media screen and (max-width: 768px) {
  .filter-container {
    width: 100%;
  }
}

.o-absolute-filter-date-in {
  border-style: none;
  border-radius: 5px;
  background-color: rgb(242, 242, 242);
  width: 100%;
  height: 40px;
  padding: 15px;
  font-size: 13px;
  color: rgb(110, 109, 109);
}
