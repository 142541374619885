.product-inline-table-edit-input {
  border: 1px solid var(--primaryColor);
  border-radius: 3px;
  max-width: 6rem;
  padding: 2px;
  background-color: transparent;
}

.product-inline-table-edit-input:disabled {
  border: none;
}

.table-container {
  min-height: 50vh;
}

.product-image-preview-overlay {
  width: 100%;
  max-height: 100vh;
  min-height: 100vh;
  position: absolute;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  background: rgba(0, 0, 0, 0.5);
  top: 0;
  left: 0;
  overflow: auto;
}


.product-img-overlay-wrapper {
  position: relative;
  display: flex;
  width: 2.5rem;
  height: 2.5rem;
  
}

.product-img-expand {
  position: absolute;
  background-color: rgba(0, 0, 0, 0.5);
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 2px;
  cursor: pointer;
  animation: zoomOut 0.4s ease-in-out;
}
